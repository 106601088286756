.canvas {
  width: 95%;
  height: 95%;
  margin: auto;
  cursor: pointer;
}

.box_canvas {
  width: 100%;
  height: 100%;
  margin: auto;
}
