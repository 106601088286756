.MuiDataGrid-menuList,
.MuiPopper-root.MuiDataGrid-menu,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  border-radius: 2rem;
}

.chakra-ui-dark .MuiDataGrid-menuList,
.chakra-ui-dark .MuiPaper-root.MuiPaper-elevation,
.chakra-ui-dark .MuiPopper-root.MuiDataGrid-panel {
  background-color: #1b254b;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  border-radius: 2rem;
}
.chakra-ui-dark .MuiSvgIcon-root,
.chakra-ui-dark .MuiButtonBase-root.MuiButton-root {
  color: #ffff;
}

.chakra-ui-dark .MuiDataGrid-overlayWrapper .MuiDataGrid-overlay {
  background-color: inherit;
}

.chakra-ui-dark .MuiDataGrid-columnsManagementFooter,
.chakra-ui-dark fieldset,
.chakra-ui-dark .MuiInput-underline:before,
.chakra-ui-dark .MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {
  border-color: #ffff;
}

.MuiDataGrid-topContainer::after {
  height: 0 !important;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
  background-color: #ffff;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #ffff;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
  background-color: #718096;
  border-radius: 10px;
}

.MuiInputBase-root fieldset {
  border-color: #e2e8f0;
}

.chakra-ui-dark .MuiInputBase-root fieldset {
  border-color: rgba(255, 255, 255, 0.16);
}
