@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
body {
  font-family: 'DM Sans', sans-serif;
}

option {
  color: black;
}

.chakra-ui-dark option {
  color: white;
}

.css-8y8lw5::-webkit-scrollbar-track {
  background-color: #ffff;
}

.css-8y8lw5::-webkit-scrollbar {
  width: 11px;
  background-color: #ffff;
}

.css-8y8lw5::-webkit-scrollbar-thumb {
  background-color: #718096;
  border-radius: 10px;
}

.sticky-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px;
  z-index: 1000; /* Adjust as needed to ensure it stays above other content */
}
