@media print {
  .printable-content {
    margin: 5mm;
  }

  .printable-content p,
  .printable-content svg {
    color: black !important;
  }

  .printable-content label {
    color: #8f9bba !important;
  }

  .printable-subheader {
    border-color: black !important;
  }

  .printable-content img {
    filter: invert(100%);
  }

  .printable-header {
    position: running(header);
    top: 0;
  }

  .printable-content {
    page-break-after: always;
  }
}
