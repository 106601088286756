.fixed_header {
  position: sticky;
  top: 0;
  z-index: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fixed_footer {
  position: sticky;
  bottom: 0;
  z-index: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
